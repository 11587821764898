<template>
  <div class="flex gap-1">
    <button
      :class="tabActive === 'lite'? 'bg-primary text-white' : 'bg-[#F4F4F4] text-black'"
      class="px-[8px] py-[10px] w-[170px] text-sm md:text-base  md:w-[240px] rounded-full h-[40px] "
      @click="handleTab('lite')"
    >
      Komchat Lite
    </button>
    <button
      :class="tabActive === 'brand'? 'bg-primary text-white' : 'bg-[#F4F4F4] text-black'"
      class="px-[8px] py-[10px] w-[170px]  md:w-[240px] rounded-full h-[40px] "
      @click="handleTab('brand')"
    >
      <div class="flex text-sm md:text-base justify-center items-center gap-1 ">
        Komchat for Brands <b-img
          width="15"
          height="15"
          class=""
          src="https://storage.googleapis.com/komerce/assets/illustration/check-blue.svg"
        />
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    tabActive: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleTab(value) {
      this.tabActive = value
      this.$emit('handleTab', value)
    },
  },
}
</script>
